@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

.game-screen {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 3em;

    .self-game {
        padding: 10px;
    }
    
    .other-game {
        padding: 10px;
    }
}