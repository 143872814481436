@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

.title-header{
    opacity:0; //header does not appear in the home page
}

.header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .title {
        margin-bottom: 0.75em;
        font-family: 'Barlow';
        font-style: normal;
        font-weight: 600;
        font-size: 64px;
        color: black;
    }

    .nickname-form {
        display: flex;
        flex-direction:row;
        gap: 1em;
    }
    

}


