@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

.other {
    font-family: 'Barlow', sans-serif;
    color: #FFA3A3;
    font-style: normal;
    font-weight: 500;

    padding-bottom: 30px;

    .others-stats {
        font-family: 'Barlow', sans-serif;
        font-size: 14px;
    }

    .others-words {
        font-family: 'Courier New', Courier, mo
        nospace, sans-serif;
        margin: auto;
        width: 560px;
        font-size: 14px;
        outline: none;
        text-align: center;
        overflow: auto;
        background-color: black;
        border-radius: 10px;
        padding: 20px;
        color: #6D7D8A;
        border-style: solid;
        border-color: #FFA3A3;
        border-width: thick;
        opacity : 0.5;
    }
}

.modal-header {
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  
  .stats-box {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    background: rgb(128, 128, 128, 0.2);
    margin-left: 1.5em;
    margin-right: 1.5em;
    
  }
  
  .stats {
    display: flex;
    justify-content: center;
    align-items: left;
    flex-direction: column;
    color: black;
   
    // font-family: ;
  }
  
  .stats-box{
    .stats{
      padding-top:1em;
      padding-bottom:1em;
      align-items:center;

    }
  
  }
  