@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600&display=swap');

.typing-box{
  font-family: 'Barlow', sans-serif;
  color: #00A19B;
  font-style: normal;
  font-weight: 500;

  .typing-test {
    font-family: 'Courier New', Courier, monospace, sans-serif;
    margin: auto;
    width: 620px;
    font-size: 1em;
    outline: none;
    text-align: center;
    overflow: auto;
    background-color: black;
    border-radius: 10px;
    padding: 20px;
    border-style: solid;
    border-color: #64B7B5;
    border-width: thick;
    font-weight:bold;
  }
  
  .curr-letter {
    text-decoration: underline;
  }

  .stats {
    font-family: 'Barlow', sans-serif;
    font-size: 18px;
  }

  .sub-text {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 18px;
  }
}


